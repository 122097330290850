import React from "react";
import ReactPlayer from "react-player";
import SimpleModal from "./simple-modal";

export default function Player({
  open,
  onClose,
  title,
  url,
}) {
  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      title={title}
      autoFullscreen={false}
    >
      <ReactPlayer
        url={url}
        width="100%"
        height="calc(100vh - 100px)"
        controls={true}
        playing={true}
      />
    </SimpleModal>
  );
}
