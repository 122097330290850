import React, { useLayoutEffect, useState } from "react";
import SimpleModal from "./simple-modal";

export default function SimpleModalIframe({
  open,
  onClose,
  title,
  url,
}) {
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);

        const iframe = document.getElementById('simple-modal-iframe');

        if (iframe) {
          iframe.style.height = `${window.screen.height-64}px`;
        }
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  
  useWindowSize();

  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      title={title}
      autoFullscreen={false}
    >
      <iframe
        id="simple-modal-iframe"
        src={url}
        width="100%"
        style={{
          border: 0,
        }}
        onLoad={(event) => {
          event.target.style.height = `${window.screen.height-64}px`;
        }}
        allowFullScreen
      />
    </SimpleModal>
  );
}
